import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{
        minHeight: "88vh",
        paddingTop: 240,
        paddingBottom: 100,
        textAlign: "center"
    }}>
        <h1 style={{marginBottom: 20}}>お探しのページが見つかりません。</h1>
        <p>お探しのページは一時的にアクセスできない状況にあるか、移動もしくは削除された可能性があります。</p>
    </div>
  </Layout>
)

export default NotFoundPage
